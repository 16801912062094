import { UserStatus } from "wave_js_sdk";

interface KeyValuedUserStatus {
  0: string;
  1: string;
}
const getUserStatusKeyValues = (): KeyValuedUserStatus => {
  const userStatusKeyValues: any = {};
  Object.entries(UserStatus).forEach((el) => {
    if (typeof el[1] !== "string") {
      userStatusKeyValues[el[1]] = el[0];
    }
  });
  return userStatusKeyValues;
};

export const userStatusChoices = Object.entries(getUserStatusKeyValues()).map(
  ([id, name]) => ({
    id,
    name: name === "Active" ? "Enable" : "Disable",
  })
);
