import React, { useMemo } from "react";
import { getAuthedTransport, login, getJobUUIDs } from "./io/utils";
import { v4 as uuid } from "uuid";

import {
  Admin,
  AuthProvider,
  DataProvider,
  HttpError,
  Resource,
  combineDataProviders,
} from "react-admin";
import {
  users,
  WaveTransportError,
  jobs,
  JobStatus,
  ImportJobs,
  UploadMultiSegmentTypeCurveCSVPayload,
  LocationMultiDataTypeCSVImportMap,
} from "wave_js_sdk";
import UsersList from "./components/UsersList";
import UsersCreate from "./components/UsersCreate";
import UsersEdit from "./components/UsersEdit";
import JobsList from "./components/JobsList";
import JobsCreate from "./components/JobsCreate";
import { ShowJob } from "./components/ShowJob";

const getUserClient = async () => users(await getAuthedTransport());
const getJobsClient = async () => jobs(await getAuthedTransport());

const getAuthProvider = (): AuthProvider => {
  return {
    async login({ username: email, password }) {
      try {
        return await login(email, password);
      } catch (err) {
        const { message, status } = err as WaveTransportError;
        throw new HttpError(message, status);
      }
    },
    async logout(params) {
      localStorage.removeItem("waveAdminToken");
      console.log("logged out");
    },
    checkAuth: (params) =>
      localStorage.getItem("waveAdminToken")
        ? Promise.resolve()
        : Promise.reject("Not authed"),
    async checkError(error: WaveTransportError) {
      // This called after dataProvider errors to check if auth related
      // TODO parse it
      console.error("authProvider:checkError", error);
      return Promise.resolve();
      // Throwing here will stop session
      //const text = await error.msgBlob.text()
      //throw text;
    },
    async getPermissions() {
      const token = localStorage.getItem("waveAdminToken");
      if (!token) throw new Error("Missing token for permissions");
      // userId is last int in token
      const userId = token.split(".").slice(-1)[0];
      const client = await getUserClient();
      return await client.getUsersTeamRole(Number(userId));
    },
    //getIdentity? : () => Promise<UserIdentity>;
  };
};

const userDataProvider = (): DataProvider => {
  return {
    //@ts-ignore
    async getList(resource, { filter, sort, pagination }) {
      try {
        const userClient = await getUserClient();
        const data = await userClient.getUsers(filter);
        return { data, total: data.length };
      } catch (err) {
        const { message, status } = err as WaveTransportError;
        throw new HttpError(message, status);
      }
    },
    //@ts-ignore
    async update(resource, { data: { status, id } }) {
      try {
        const userClient = await getUserClient();
        await userClient.changeUserStatus({
          id,
          status: parseInt(status),
        });
        return { data: { id } };
      } catch (err) {
        const { message, status } = err as WaveTransportError;
        throw new HttpError(message, status);
      }
    },
    //@ts-ignore
    async create(resource, { data: userData }) {
      try {
        const userClient = await getUserClient();
        const data = await userClient.createUser(userData);
        return { data };
      } catch (err) {
        const { message, status } = err as WaveTransportError;
        throw new HttpError(message, status);
      }
    },
    //@ts-ignore
    async getOne(resource, { id }) {
      const userId = typeof id === "string" ? parseInt(id) : id;
      const userClient = await getUserClient();
      const {
        userRole: userTeamRole,
        teamId,
        teamName,
      } = (await userClient.getUsersTeamRole(userId))[0];
      return { data: { id: userId, userTeamRole, teamId, teamName } };
    },
  };
};

const jobsDataProvider = (): DataProvider => {
  return {
    //@ts-ignore
    async getList(resource, { filter, sort, pagination }) {
      const jobClient = await getJobsClient();

      try {
        const { jobs } = await jobClient.getJobs({});
        return {
          data: jobs.map((job) => ({ ...job, id: job.uuid })),
          total: jobs.length,
        };
      } catch (err) {
        const { message, status } = err as WaveTransportError;
        throw new HttpError(message, status);
      }
    },
    //@ts-ignore
    async getOne(resource, { id: uuid }) {
      const jobClient = await getJobsClient();

      try {
        const {
          jobs: [job],
          status,
        } = await jobClient.getJobs({
          jobUUID: uuid,
        });
        return {
          data: {
            id: job.uuid, //to match the requested record id, enforced by react-admin
            status,
            type: job.type,
            uuid: job.uuid,
            batchId: job.batchId,
            batchName: job.batchName,
            consumedAt: job.consumedAt,
            consumerId: job.consumerId,
            output: job.output?.output,
            result: job.output?.result,
          },
        };
      } catch (err) {
        const { message, status } = err as WaveTransportError;
        throw new HttpError(message, status);
      }
    },
    //@ts-ignore
    async create(resource, { data: input }) {
      const jobClient = await getJobsClient();
      try {
        if (input.fileDetails) {
          // multi-segment curve upload
          if (input.ImportJobs === ImportJobs.CURVE_CSV_TYPECURVE_IMPORT) {
            const dataMap = {
              id: uuid(),
              dateFormat: input.dateFormat,
              forecastDataTypeId: input.forecastDataTypeId,
              curveTypeId: input.curveTypeId,
              curvesNameTemplate: input.curvesNameTemplate,
              locationIdType: input.locationIdType,
              locationCurveDefaultMap: {
                replaceExistingDefaults: input.replaceExistingDefaults,
                addCurvesAsLocationDefaults: input.addCurvesAsLocationDefaults,
              },
              coloumnMap: {
                locationId: input.locationId,
                curveStartDate: input.curveStartDate,
                curveEndDate: input.curveEndDate,
                curveMethod: input.curveMethod,
                eqParam: {
                  m: input.m,
                  c: input.c,
                  tcDay: input.tcDay,
                  tcStartVolume: input.tcStartVolume,
                  qi: input.qi,
                  di: input.di,
                  b: input.b,
                },
              },
            };
            const { filename, file: blobURL } = input.fileDetails;
            const { batchUUID } = await jobClient.queueJob({
              type: ImportJobs.CURVE_CSV_TYPECURVE_IMPORT,
              batchName: input.name,
              payload: {
                file: await (await fetch(blobURL)).blob(),
                filename,
                dataMap,
              } as UploadMultiSegmentTypeCurveCSVPayload,
            });
            return {
              data: {
                id: await getJobUUIDs(jobClient, batchUUID as string),
              },
            };
          }
          // multi-type locations chemicals ts upload
          else if (
            input.ImportJobs === ImportJobs.LOCATIONS_CSV_MULTI_TYPE_IMPORT
          ) {
            const { filename, file: blobURL } = input.fileDetails;
            const { batchUUID } = await jobClient.queueJob({
              type: ImportJobs.LOCATIONS_CSV_MULTI_TYPE_IMPORT,
              batchName: input.name,
              payload: {
                filename,
                file: await (await fetch(blobURL)).blob(),
                dataMap: input.dataMap as LocationMultiDataTypeCSVImportMap,
              },
            });
            return {
              data: {
                id: await getJobUUIDs(jobClient, batchUUID as string),
              },
            };
          }
        }
        // wbr-import
        else if (
          [ImportJobs.WBR_IMPORT_JOB, ImportJobs.WBR_EXPORT_DATA_JOB].includes(
            input.ImportJobs
          )
        ) {
          const { batchUUID } = await jobClient.queueJob({
            type: ImportJobs.WBR_IMPORT_JOB,
            batchName: input.name,
          });
          return {
            data: {
              id: await getJobUUIDs(jobClient, batchUUID as string),
            },
          };
        }
        throw new HttpError("Something went wrong!", 500);
      } catch (err) {
        const { message, status } = err as WaveTransportError;
        throw new HttpError(message, status);
      }
    },
  };
};

const getDataProvider = combineDataProviders((resource): DataProvider => {
  switch (resource) {
    case "users":
      return userDataProvider();
    case "jobs":
      return jobsDataProvider();
    default:
      throw new Error(`Unknown resource: ${resource}`);
  }
});

const App = () => {
  const authProvider = useMemo(() => getAuthProvider(), []);
  return (
    <Admin
      dataProvider={getDataProvider}
      authProvider={authProvider}
      requireAuth
    >
      <Resource
        name='users'
        edit={UsersEdit}
        list={UsersList}
        create={UsersCreate}
      />
      <Resource
        name='jobs'
        list={JobsList}
        create={JobsCreate}
        show={ShowJob}
      />
    </Admin>
  );
};

export default App;
