import * as React from "react";
import {
  Edit,
  SimpleForm,
  SelectInput,
  TextField,
  useNotify,
  useRedirect,
  useRefresh,
  Toolbar,
  ToolbarProps,
  SaveButton,
  Labeled,
} from "react-admin";
import { userStatusChoices } from "../hooks/useSdk";

const UserEditToolbar = (props: ToolbarProps) => {
  return (
    <>
      <Toolbar {...props}>
        <SaveButton />
      </Toolbar>
    </>
  );
};
const UsersEdit: React.FunctionComponent = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("User updated", { type: "info" });
    redirect("/users");
    refresh();
  };
  return (
    <Edit
      mutationOptions={{ onSuccess: onSuccess }}
      mutationMode='pessimistic'
      {...props}
    >
      <SimpleForm toolbar={<UserEditToolbar />}>
        <Labeled label='Team Role'>
          <TextField source='userTeamRole' />
        </Labeled>

        <Labeled label='Team Id'>
          <TextField source='teamId' />
        </Labeled>

        <Labeled label='Team Name'>
          <TextField source='teamName.name' />
        </Labeled>

        <Labeled label='Id'>
          <TextField source='id' />
        </Labeled>

        <Labeled label='status'>
          <SelectInput
            source='status'
            choices={userStatusChoices}
          ></SelectInput>
        </Labeled>
      </SimpleForm>
    </Edit>
  );
};
export default UsersEdit;
