import * as React from "react";
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  SelectInput,
  useNotify,
  useRedirect,
  useRefresh,
} from "react-admin";
import { UserTeamRole } from "wave_js_sdk";

const UsersCreate: React.FunctionComponent = (props) => {
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("User created", { type: "info" });
    redirect("/users");
    refresh();
  };

  return (
    <Create mutationOptions={{ onSuccess: onSuccess }} {...props}>
      <SimpleForm>
        <TextInput source='username' />
        <TextInput source='password' />
        <TextInput source='email' />
        <NumberInput defaultValue={1} source='teamId' />
        <SelectInput
          source='teamRole'
          choices={Object.entries(UserTeamRole).map(([name, id]) => ({
            id,
            name,
          }))}
        ></SelectInput>
      </SimpleForm>
    </Create>
  );
};
export default UsersCreate;
