import React, { useRef, useState } from "react";
import {
  Create,
  SimpleForm,
  SelectInput,
  useNotify,
  useRedirect,
  useRefresh,
  TextInput,
  FileInput,
  FileField,
  NumberInput,
  BooleanInput,
  FormTab,
  Tab,
  TabbedForm,
  useCreate,
  Button,
} from "react-admin";
import {
  ImportJobs,
  LocationDataTypeId,
  locationIdType,
  LocationDataTypeColomnMap,
} from "wave_js_sdk";
import { MultiTypeDataMapTemplates } from "../io/utils";
import Typography from "@mui/material/Typography";

const JobsCreate = (props: any) => {
  const [selectedImportJob, setSelectedImportJob] = useState(null);
  const batchName = useRef("");
  const [dataMapTemplate, setDataMapTemplate] = useState<
    LocationDataTypeColomnMap[]
  >([]);
  const [formCount, setFormCount] = useState(dataMapTemplate.length);

  const [create] = useCreate();
  const notify = useNotify();
  const refresh = useRefresh();
  const redirect = useRedirect();

  const onSuccess = () => {
    notify("Job created", { type: "info" });
    redirect("/jobs");
    refresh();
  };

  const onError = (error: any) => {
    notify(error.message, { type: "warning" });
    redirect("/jobs");
    refresh();
  };

  const handleAddForm = () => {
    setFormCount(formCount + 1);
  };

  const prePopulateColumnMaps = (template: MultiTypeDataMapTemplates) => {
    let columnMap: LocationDataTypeColomnMap[] = [];

    if (template === MultiTypeDataMapTemplates.Millipore) {
      const columnMapCommonFields = {
        id: "name",
        locationForeignIdColumn: 2,
        dataTimebucketFieldColumn: 1,
        dataTimebucketFieldDateFormat: ["MM/dd/yy"],
      };
      // Millipore columns
      columnMap = [
        {
          dataType: LocationDataTypeId.Hydrocarbons,
          locationType: "facility",
          dataFieldColumn: 4,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Iron,
          locationType: "facility",
          dataFieldColumn: 5,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Carbonates,
          locationType: "facility",
          dataFieldColumn: 6,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.AcidInsoluble,
          locationType: "facility",
          dataFieldColumn: 7,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.TotalSuspendedSolids,
          locationType: "facility",
          dataFieldColumn: 8,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.MilliporeFilteredVolume,
          locationType: "facility",
          dataFieldColumn: 9,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.TOG,
          locationType: "facility",
          dataFieldColumn: 10,
          ...columnMapCommonFields,
        },
      ];
    } else if (template === MultiTypeDataMapTemplates.CWA) {
      // CWA columns
      const columnMapCommonFields = {
        id: "name",
        locationForeignIdColumn: 1,
        dataTimebucketFieldColumn: 2,
        dataTimebucketFieldDateFormat: ["MM/dd/yy"],
      };
      columnMap = [
        {
          dataType: LocationDataTypeId.TDS,
          locationType: "facility",
          dataFieldColumn: 4,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.SpecificGravity,
          locationType: "facility",
          dataFieldColumn: 5,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.pH,
          locationType: "facility",
          dataFieldColumn: 6,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Temp,
          locationType: "facility",
          dataFieldColumn: 7,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.DissolvedCO2,
          locationType: "facility",
          dataFieldColumn: 8,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.H2S,
          locationType: "facility",
          dataFieldColumn: 9,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Chloride,
          locationType: "facility",
          dataFieldColumn: 10,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Sulfate,
          locationType: "facility",
          dataFieldColumn: 11,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Bicarbonate,
          locationType: "facility",
          dataFieldColumn: 12,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Calcium,
          locationType: "facility",
          dataFieldColumn: 13,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Magnesium,
          locationType: "facility",
          dataFieldColumn: 14,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Barium,
          locationType: "facility",
          dataFieldColumn: 15,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Strontium,
          locationType: "facility",
          dataFieldColumn: 16,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Sodium,
          locationType: "facility",
          dataFieldColumn: 17,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Potassium,
          locationType: "facility",
          dataFieldColumn: 18,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Iron,
          locationType: "facility",
          dataFieldColumn: 19,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.Manganese,
          locationType: "facility",
          dataFieldColumn: 20,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.CaCO3SI70,
          locationType: "facility",
          dataFieldColumn: 21,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.CaCO3Weight70,
          locationType: "facility",
          dataFieldColumn: 22,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.CaCO3SI145,
          locationType: "facility",
          dataFieldColumn: 23,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.CaCO3Weight145,
          locationType: "facility",
          dataFieldColumn: 24,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.CaSO4SI70,
          locationType: "facility",
          dataFieldColumn: 25,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.CaSO4Weight70,
          locationType: "facility",
          dataFieldColumn: 26,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.CaSO4SI145,
          locationType: "facility",
          dataFieldColumn: 27,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.CaSO4Weight145,
          locationType: "facility",
          dataFieldColumn: 28,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.BaSO4SI70,
          locationType: "facility",
          dataFieldColumn: 29,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.BaSO4Weight70,
          locationType: "facility",
          dataFieldColumn: 30,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.BaSO4SI145,
          locationType: "facility",
          dataFieldColumn: 31,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.BaSO4Weight145,
          locationType: "facility",
          dataFieldColumn: 32,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.SrSO4SI70,
          locationType: "facility",
          dataFieldColumn: 33,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.SrSO4Weight70,
          locationType: "facility",
          dataFieldColumn: 34,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.SrSO4SI145,
          locationType: "facility",
          dataFieldColumn: 35,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.SrSO4Weight145,
          locationType: "facility",
          dataFieldColumn: 36,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.FeCO3SI70,
          locationType: "facility",
          dataFieldColumn: 37,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.FeCO3Weight70,
          locationType: "facility",
          dataFieldColumn: 38,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.FeCO3SI145,
          locationType: "facility",
          dataFieldColumn: 39,
          ...columnMapCommonFields,
        },
        {
          dataType: LocationDataTypeId.FeCO3Weight145,
          locationType: "facility",
          dataFieldColumn: 40,
          ...columnMapCommonFields,
        },
      ];
    } else if (template === MultiTypeDataMapTemplates.ATP) {
      // ATP columns
      const columnMapCommonFields = {
        id: "name",
        locationForeignIdColumn: 2,
        dataTimebucketFieldColumn: 1,
        dataTimebucketFieldDateFormat: ["MM/dd/yy"],
      };
      columnMap = [
        {
          dataType: LocationDataTypeId.ATP,
          locationType: "facility",
          dataFieldColumn: 4,
          ...columnMapCommonFields,
        },
      ];
    }
    // set template & form count
    setDataMapTemplate(columnMap);
    setFormCount(columnMap.length);
  };

  const getFormsDetails = (formsFields: any) => {
    const { fileDetails, ...rest } = formsFields;
    const locationDataTypeColomnMap: LocationDataTypeColomnMap[] = new Array(
      formCount
    )
      .fill(undefined)
      .map((_, idx) => ({
        id: rest[`id${idx}`],
        dataType: rest[`dataType${idx}`],
        locationType: rest[`locationType${idx}`],
        dataFieldColumn: rest[`dataFieldColumn${idx}`],
        locationForeignIdColumn: rest[`locationForeignIdColumn${idx}`],
        dataTimebucketFieldColumn: rest[`dataTimebucketFieldColumn${idx}`],
        dataTimebucketFieldDateFormat: [
          ...[rest[`dataTimebucketFieldDateFormat${idx}`]].flat(),
        ],
      }));

    return { dataMap: { locationDataTypeColomnMap }, fileDetails };
  };

  return (
    <Create
      mutationOptions={{ onSuccess: onSuccess, onError: onError }}
      {...props}
    >
      <>
        <SimpleForm>
          <SelectInput
            source='ImportJobs'
            choices={Object.entries(ImportJobs).map(([key, value]) => {
              if (isNaN(Number(key))) {
                return {
                  id: value,
                  name: key,
                };
              }
            })}
            onChange={({ target: { value } }) => setSelectedImportJob(value)}
          />
          {selectedImportJob !== ImportJobs.LOCATIONS_CSV_MULTI_TYPE_IMPORT && (
            <TextInput
              source='name'
              onChange={({ target: { value } }) => (batchName.current = value)}
            />
          )}
        </SimpleForm>

        {selectedImportJob === ImportJobs.CURVE_CSV_TYPECURVE_IMPORT && (
          <TabbedForm
            onSubmit={(fields) =>
              create(
                "jobs",
                {
                  data: {
                    ...fields,
                    name: batchName.current,
                    ImportJobs: ImportJobs.CURVE_CSV_TYPECURVE_IMPORT,
                  },
                },
                { onSuccess, onError }
              )
            }
          >
            <FormTab value='uploadFile' label='Upload File'>
              <FileInput source='fileDetails' label='File'>
                <FileField source='file' title='filename' />
              </FileInput>
            </FormTab>

            <FormTab label='Settings'>
              <Tab label='settings'>
                <TextInput source='dateFormat' defaultValue={"yyyy-MM-dd"} />
                <SelectInput
                  source='forecastDataTypeId'
                  choices={Object.entries(LocationDataTypeId).map(
                    ([key, value]) => {
                      if (isNaN(Number(key))) {
                        return {
                          id: value,
                          name: key,
                        };
                      }
                    }
                  )}
                />
                <SelectInput
                  source='curveTypeId'
                  choices={[
                    { key: "Pop Forecasts", value: 1 },
                    { key: "Pdp Forecasts", value: 2 },
                    { key: "Operational Capacity", value: 3 },
                    { key: "Permanent Capacity", value: 4 },
                  ].map(({ key: name, value: id }) => ({
                    name,
                    id,
                  }))}
                />
                <TextInput
                  source='curvesNameTemplate'
                  defaultValue={"wave-curve-%%nodeId%% TC curve"}
                  fullWidth={true}
                />
                <SelectInput
                  source='locationIdType'
                  choices={Object.entries(locationIdType).map(
                    ([key, value]) => {
                      if (isNaN(Number(key))) {
                        return {
                          id: value,
                          name: key,
                        };
                      }
                    }
                  )}
                />
              </Tab>
              <Tab label='locationCurveDefaultMap'>
                <BooleanInput
                  source='addCurvesAsLocationDefaults'
                  defaultValue={true}
                />
                <BooleanInput
                  source='replaceExistingDefaults'
                  defaultValue={true}
                />
              </Tab>
            </FormTab>

            <FormTab value='eqParamColms' label='Column maps'>
              <Tab label='eqParamColms'>
                <NumberInput
                  source='locationId'
                  label='Location Id'
                  defaultValue={0}
                />
                <NumberInput
                  source='curveStartDate'
                  label='Curve Start Date'
                  defaultValue={1}
                />
                <NumberInput
                  source='curveEndDate'
                  label='Curve End Date'
                  defaultValue={2}
                />
                <NumberInput
                  source='curveMethod'
                  label='Curve Method'
                  defaultValue={3}
                />

                <NumberInput source='m' label='eqParam-m' defaultValue={4} />
                <NumberInput source='c' label='eqParam-c' defaultValue={5} />
                <NumberInput
                  source='tcDay'
                  label='eqParam-tcDay'
                  defaultValue={6}
                />
                <NumberInput
                  source='tcStartVolume'
                  label='eqParam-tcStartVolume'
                  defaultValue={7}
                />
                <NumberInput source='qi' label='eqParam-qi' defaultValue={8} />
                <NumberInput source='di' label='eqParam-di' defaultValue={9} />
                <NumberInput source='b' label='eqParam-b' defaultValue={10} />
              </Tab>
            </FormTab>
          </TabbedForm>
        )}

        {selectedImportJob === ImportJobs.LOCATIONS_CSV_MULTI_TYPE_IMPORT && (
          <>
            <Typography variant='body1' align='left' margin={5}>
              Note:
              <br />
              To ensure that the time-series data for each location in the CSV
              is correctly mapped to the corresponding location in WAVE,
              <br /> there must be an existing location in WAVE with a meta
              named "name" and a value matching the location name being
              imported.
            </Typography>
            <TabbedForm
              onSubmit={(fields) =>
                create(
                  "jobs",
                  {
                    data: {
                      ...getFormsDetails(fields),
                      name: batchName.current,
                      ImportJobs: ImportJobs.LOCATIONS_CSV_MULTI_TYPE_IMPORT,
                    },
                  },
                  { onSuccess, onError }
                )
              }
            >
              <FormTab value='uploadFile' label='Upload File'>
                <FileInput source='fileDetails' label='File'>
                  <FileField source='file' title='filename' />
                </FileInput>
                <SelectInput
                  label='Templates'
                  source='fileDataMapTemplates'
                  choices={Object.entries(MultiTypeDataMapTemplates).map(
                    ([name, id]) => ({
                      id,
                      name,
                    })
                  )}
                  onChange={({ target: { value } }) => {
                    prePopulateColumnMaps(value);
                  }}
                />
              </FormTab>

              {formCount > 0 &&
                new Array(formCount).fill(undefined).map((_, index) => (
                  <FormTab
                    key={index}
                    value={`locationDataTypeColomnMaps${index}`}
                    label={`Column maps`}
                  >
                    <Tab label={`locationDataTypeColomnMaps`}>
                      <TextInput
                        source={`id${index}`}
                        label={`Id`}
                        defaultValue={"name"}
                      />
                      <SelectInput
                        source={`dataType${index}`}
                        label={`Data Type`}
                        defaultValue={
                          dataMapTemplate[index]
                            ? dataMapTemplate[index].dataType
                            : LocationDataTypeId.BaSO4SI145
                        }
                        choices={Object.entries(LocationDataTypeId).map(
                          ([key, value]) => {
                            if (isNaN(Number(key))) {
                              return {
                                id: value,
                                name: key,
                              };
                            }
                          }
                        )}
                      />
                      <TextInput
                        source={`locationType${index}`}
                        label={`Location Type`}
                        defaultValue={"facility"}
                      />

                      <NumberInput
                        source={`dataFieldColumn${index}`}
                        label={`Data Column`}
                        defaultValue={
                          dataMapTemplate[index]
                            ? dataMapTemplate[index].dataFieldColumn
                            : 4
                        }
                      />
                      <NumberInput
                        source={`locationForeignIdColumn${index}`}
                        label={`Location Foreign Id Column`}
                        defaultValue={
                          dataMapTemplate[index]
                            ? dataMapTemplate[index].locationForeignIdColumn
                            : 2
                        }
                      />
                      <NumberInput
                        source={`dataTimebucketFieldColumn${index}`}
                        label={`Date Column`}
                        defaultValue={
                          dataMapTemplate[index]
                            ? dataMapTemplate[index].dataTimebucketFieldColumn
                            : 1
                        }
                      />
                      <TextInput
                        source={`dataTimebucketFieldDateFormat${index}`}
                        label={`Date Format`}
                        defaultValue={
                          dataMapTemplate[index]
                            ? dataMapTemplate[index]
                                .dataTimebucketFieldDateFormat
                            : "MM/dd/yy"
                        }
                      />
                    </Tab>
                  </FormTab>
                ))}
            </TabbedForm>

            <Button label='Add Column Map' onClick={handleAddForm} />
          </>
        )}
      </>
    </Create>
  );
};

export default JobsCreate;
