import {
  transport as _transport,
  users as _users,
  jobs as _jobs,
  WaveTransportError,
} from "wave_js_sdk";

const getRuntimeCfg = async () => {
  try {
    const result = await fetch("runtime.config.json");
    if (result.status && result.status <= 299) {
      return await result.json();
    }
    return undefined;
  } catch (err) {
    console.error("error fetching runtimecfg", err);
    return undefined;
  }
};

const getEndpointUrl = (runtimeConfig?: Record<string, string>) =>
  `${runtimeConfig?.WAVE_API_URL || window.location.hostname}:${
    runtimeConfig?.API_PORT || 8080
  }`;

const login = async (email: string, pass: string) => {
  if (!email || !pass)
    throw new Error("Cannot get authedtransport without login");

  const cfg = await getRuntimeCfg();
  const endpoint = getEndpointUrl(cfg);
  const users = _users(
    // @ts-ignore
    _transport({ fetch: window.fetch }),
    `${endpoint}/auth`
  );

  const { token: apiToken } = await users.loginEmailPass({ email, pass });

  localStorage.setItem("waveAdminToken", apiToken);
  return apiToken;
};

const makeAuthedTransport = async () => {
  const cfg = await getRuntimeCfg();
  const endpoint = getEndpointUrl(cfg);

  const apiToken = localStorage.getItem("waveAdminToken") || "";

  // inject the token into your transport
  return _transport({
    endpoint,
    apiToken,
    // @ts-ignore
    fetch: window.fetch,
  });
};

let transport: Promise<ReturnType<typeof _transport>> | undefined;
const getAuthedTransport = () => {
  if (!transport || !localStorage.getItem("waveAdminToken")) {
    transport = makeAuthedTransport();
  }
  return transport;
};

const resetTransport = () => (transport = undefined);

const transportQueryErrorHandler = async (err: WaveTransportError) => {
  const msg = err?.message;
  console.error("[ERROR]", msg);
  return msg;
};

const getJobUUIDs = async (
  jobClient: ReturnType<typeof _jobs>,
  batchUUID: string
) => {
  const { jobs } = await jobClient.getJobs({
    jobBatchUUID: [batchUUID],
  });
  return jobs.map(({ uuid }) => uuid);
};

export {
  getAuthedTransport,
  transportQueryErrorHandler,
  resetTransport,
  getRuntimeCfg,
  getEndpointUrl,
  login,
  getJobUUIDs,
};

export enum MultiTypeDataMapTemplates {
  Millipore = "Millipore",
  CWA = "CWA",
  ATP = "ATP",
}
