import React from 'react';
import { List, Datagrid, TextField, EmailField, EditButton, FilterForm, FilterButton, TopToolbar, CreateButton, ExportButton } from 'react-admin';
import { useRecordContext } from 'ra-core';
import styled from 'styled-components';
import { BooleanInput } from 'react-admin';
import { useState } from 'react';

const StatusChip = styled.span<{ $isActive: boolean }>`
  display: inline-block;
  padding: 4px 12px;
  border-radius: 16px;
  font-size: 0.875rem;
  background-color: ${props => props.$isActive ? '#e6f4ea' : '#fce8e6'};
  color: ${props => props.$isActive ? '#137333' : '#c5221f'};
`;

const StatusField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  const value = record?.[source];
  const isActive = value === 1;
  
  return (
    <StatusChip $isActive={isActive}>
      {isActive ? '✅ Active' : '❌ Inactive'}
    </StatusChip>
  );
};

const DateField = ({ source }: { source: string }) => {
  const record = useRecordContext();
  const value = record?.[source];
  
  if (!value) return null;
  
  const date = new Date(value);
  const day = date.getDate();
  const month = date.toLocaleString('en-US', { month: 'short' });
  const year = date.getFullYear();
  
  return <span>{`${day} ${month} ${year}`}</span>;
};

const ListActions = ({ showOnlyActive, setShowOnlyActive }: {
  showOnlyActive: boolean;
  setShowOnlyActive: (value: boolean) => void;
}) => {
  return (
    <TopToolbar>
      <FilterLabel>
        <input
          type="checkbox"
          checked={showOnlyActive}
          onChange={(e) => setShowOnlyActive(e.target.checked)}
        />
        Show only active users
      </FilterLabel>
      <CreateButton />
      <ExportButton />
      
    </TopToolbar>
  );
};

const FilterLabel = styled.label`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  margin-left: 1rem;
  cursor: pointer;
`;

const UserList: React.FunctionComponent = (props) => {
  const [showOnlyActive, setShowOnlyActive] = useState(false);
  const filters = showOnlyActive ? { status: 1 } : undefined;
  
  return (
    <List 
      {...props} 
      actions={<ListActions 
        showOnlyActive={showOnlyActive} 
        setShowOnlyActive={setShowOnlyActive}
      />} 
      filter={filters}
    >
      <Datagrid rowClick="edit">
        <TextField source="id" />
        <TextField source="username" />
        <EmailField source="email" />
        <StatusField source="status" />
        <DateField source="createdAt" />
        <EditButton />
      </Datagrid>
    </List>
  );
};

export default UserList;
