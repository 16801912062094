import * as React from "react";
import {
  List,
  Datagrid,
  TextField,
  BulkDeleteButton,
  DateField,
} from "react-admin";

const BulkActionButtons: React.FunctionComponent = (props) => (
  <React.Fragment>
    <BulkDeleteButton disabled={true} />
  </React.Fragment>
);

const JobsList: React.FunctionComponent = (props) => {
  return (
    <List {...props} bulkActionButtons={<BulkActionButtons />}>
      <Datagrid rowClick='show'>
        <TextField source='uuid' />
        <DateField source='jobQueuedAt' showTime={true} />
        <TextField source='type' />
        <TextField source='batchName' label='name' />
        <TextField source='output.result' />
        <DateField source='consumedAt' showTime={true} />
      </Datagrid>
    </List>
  );
};
export default JobsList;
