import * as React from "react";
import { Show, Tab, TabbedShowLayout, TextField } from "react-admin";

export const ShowJob = () => (
  <Show title='job-details' resource='jobs'>
    <TabbedShowLayout>
      <Tab label='summary'>
        <TextField source='status' />
        <TextField source='type' />
        <TextField source='batchName' label='Name' />
        <TextField source='uuid' label='Job UUID' />
        <TextField source='batchId' label='Batch UUID' />
      </Tab>
      <Tab label='output'>
        <TextField source='result' />
        <TextField source='output' />
        <TextField source='consumedAt' label='Consumed At' />
        <TextField source='consumerId' label='Consumer Id' />
      </Tab>
    </TabbedShowLayout>
  </Show>
);
